.request-container {
  box-sizing: border-box;
  padding: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  max-width: 630px;
  font-size: 13px;
  color: black;
  margin: auto;
}
.request-title {
  font-size: 2em;
  color: #1e5593;
  margin: 0;
}
.request-description {
  line-height: 22px;
  margin: 0;
}
.catalog-link {
  display: block;
  color: #1e5593;
  text-decoration: none;
  line-height: 22px;
  margin-top: 12px;
}
.request-form-item {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.request-form-input-label {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 13pt;
  color: #005496;
}
.request-form-input-label span {
  color: red;
}
.request-form-side {
  flex: 1;
}
.request-form-input {
  width: 100%;
  background-color: #fff;
  border: 1px solid #c9c9c9;
  font-size: 11pt;
  border-radius: 4px;
  font-family: Arial, Helvetica, sans-serif;
  color: #979797;
  box-shadow: inset 0 1px 2px #0a0a0a1a;
  padding: 8px 20px;
  box-sizing: border-box;
  display: block;
  opacity: 0.8;
}
.request-form-input:focus {
  opacity: 1;
  color: #747474;
  background-color: #f9f9f9;
}
.request-error {
  color: red;
}
.error-msg {
  color: #721c24;
}
.request-item {
  margin-top: 20px;
}
.check-item {
  display: flex;
  align-items: center;
}
.check-label {
  margin-left: 4px;
}
.request-submit {
  margin-top: 20px;
  border-radius: 5px;
  background-color: #005496;
  padding: 12px 26px;
  outline: none;
  font-weight: bold;
  font-size: 11pt;
  color: white;
  border: none;
  cursor: pointer;
}